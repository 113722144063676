<template>
	<!-- 订单中心页 -->
	<div class="order-center">
		<div class="page-title">
			<div class="title-line"></div>
			<span>订单中心</span>
			<span class="pay_notice"><!-- (*未支付订单将在15分钟后取消！) --></span>
		</div>
		<div class="order-type">
			<div class="typy-item" v-for="(item,index) in orderTab" :key="index"
				:style="{'font-weight':(selecttype == index? '600':'400')}" @click="changeIndex(index,item.id)">
				{{item.name}}
				<div class="type-line" v-if="selecttype == index"></div>
				<div class="type-line type-zhanwei" v-else></div>
			</div>
		</div>
		<div class="table">
			<el-table :data="tableData">
				<el-table-column prop="order_sn" align="center" header-align="center" label="订单编号" width="210">
				</el-table-column>
				<el-table-column prop="day" label="婚期" align="center" header-align="center" width="130">
				</el-table-column>
				<el-table-column prop="ph_user" label="档位" align="center" header-align="center" min-width="150">
				</el-table-column>
				<el-table-column prop="name" label="姓名" align="center" header-align="center" width="120">
				</el-table-column>
				<el-table-column prop="total_amount" label="订单总金额" align="center" header-align="center" width="120">
				</el-table-column>
        <el-table-column prop="ought_price" label="应支付金额" align="center" header-align="center" width="120">
        </el-table-column>
				<el-table-column prop="pay_price" label="已支付金额" align="center" header-align="center" width="120">
				</el-table-column>
				<el-table-column prop="discount_price" label="优惠金额" align="center" header-align="center" min-width="100">
				</el-table-column>
				<el-table-column prop="surplus_price" label="未支付金额" align="center" header-align="center" width="110">
					<template slot-scope="scope">
						<span style="color: #EB1919;">{{scope.row.surplus_price}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="status_name" label="订单状态" align="center" header-align="center" min-width="120">
					<template slot-scope="scope">
						<span :style="{'color':(scope.row.status == '0'?'#FF9C32':'')}">{{scope.row.status_name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" header-align="center" min-width="200">
					<template slot-scope="scope">
						<!-- <el-button v-if="scope.row.status == '0'" class="red-color red-color1" @click="handleClick(scope.row)"
							type="text" size="small">去支付</el-button> -->
						<el-button type="text" size="small" class="red-color blue-color" @click="DetailsClick(scope.row.id)">查看详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="page-num">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="page_no" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
				layout="total, prev, pager, next, sizes,jumper" :total="total">
			</el-pagination>
		</div>

		<!-- 订单详情弹框 -->
		<el-dialog title="订单详情" :visible.sync="centerDialogVisible" center>
			<div class="order-details">
				<div class="fram-title">预定信息：</div>
				<div class="predetermine">
					<div class="pre-item">
						<div class="pre-item-name">预定内容：</div>
						<span>{{details_info.content}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">档级：</div>
						<span class="yellow-color">{{details_info.grade}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">姓名：</div>
						<span>{{details_info.name}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">电话：</div>
						<span>{{details_info.phone}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">婚期：</div>
						<span>{{details_info.day}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">典礼时间：</div>
						<span>{{details_info.time}}</span>
					</div>

					<div class="pre-item" style="width: 100%;">
						<div class="pre-item-name">举办城市：</div>
						<span>{{details_info.address}}</span>
					</div>
					<div class="pre-item" style="width: 100%;">
						<div class="pre-item-name">酒店名称：</div>
						<span>{{details_info.hotel}}</span>
					</div>
					<div class="pre-item" style="width: 100%;">
						<div class="pre-item-name">客服：</div>
						<span>{{details_info.service}}</span>
					</div>
				</div>
				<div class="split-line"></div>
				<div class="fram-title">订单信息：</div>
				<div class="predetermine order-info">
					<div class="pre-item">
						<div class="pre-item-name">订单编号：</div>
						<span>{{details_info.order_sn}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">下单时间：</div>
						<span>{{details_info.create_time}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">订单总金额：</div>
						<span>{{details_info.total_amount}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">订单状态：</div>
						<span :style="{'color':(details_info.status == '未支付' ?'#FBB448':'#333333')}">{{details_info.status}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">优惠金额：</div>
						<span class="pink-color">-{{details_info.discount_price}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">差旅费：</div>
						<span class="pink-color">+{{details_info.travel_price}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">已支付金额：</div>
						<span>{{details_info.pay_price}}</span>
					</div>
					<div class="pre-item">
						<div class="pre-item-name">未支付金额：</div>
						<span class="red-color">{{details_info.surplus_price}}</span>
					</div>
					<div class="pre-item" style="width: 100%;">
						<div class="pre-item-name">备注信息：</div>
						<span>{{details_info.desc}}</span>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 去支付框 -->
		<div class="all-pay-box">
			<el-dialog title="订单支付" :visible.sync="PaydialogVisible">
				<div class="pay-box">
					<div class="pay-left">
						<div class="pay-list">
							<span>订单总金额：</span>
							<div><span class="p-b-color">{{pay_info.total_amount}}</span>元</div>
						</div>
						<div class="pay-list">
							<span>红包优惠：</span>
							<div><span class="p-xr-color">-{{pay_info.discount_price}}</span>元</div>
						</div>
						<div class="pay-list">
							<span>差旅费：</span>
							<div><span class="p-xr-color">{{pay_info.travel_price}}</span>元</div>
						</div>
						<div class="pay-list">
							<span>支付定金：</span>
							<div><span class="p-dr-color">{{pay_info.deposit}}</span>元</div>
						</div>
					</div>
					<div class="pay-right">
						<div class="pay-code">
							<img src="../../assets/images/home/code.png" alt="">
						</div>
						<div class="pay-notice">打开微信，扫描二维码支付</div>
					</div>
				</div>
			</el-dialog>
		</div>

	</div>
</template>

<script>
	import{
		getdingdanList,
		getdingdandetailList
	}from '@/api/joggle.js'
	export default {
		data() {
			return {
				orderTab: [{
					id:1,
					name:'摄影订单'
				},{
					id:2,
					name:'摄像订单'
				}],
				selecttype: 0,
				tableData: [],
				centerDialogVisible: false, // 详情弹框
				PaydialogVisible:false, // 支付弹框
				tab_id:1, //1.摄影订单 2.摄像订单
				page_no:1, //当前页
				pageSize:10, //当前页数据数
				total:0, //总数
				pay_info:{}, // 订单支付信息
				details_info:{} , //订单详情信息
			}
		},

		created() {
			var that = this
			// 保证页面刷新时保持原页面选择不变
			var tab_index = localStorage.getItem('tab_index');
			if(tab_index){
				that.selecttype = tab_index;
				that.tab_id = that.orderTab[that.selecttype].id;
			}
			var page_no = localStorage.getItem('page_no');
			if(page_no){
				that.page_no =Number(page_no);
			}
			that.getList();
		},
		methods: {
			// 获取订单列表
			getList(){
				var that = this
				var obj = {
					type:that.tab_id,
					page_no:that.page_no,
					page_size:that.pageSize
				}
				getdingdanList(obj).then(res => {
					if(res.code == 1){
						var data = res.data;
						that.total = data.count;
						that.tableData = data.list;

					}
				})
			},
			// 改变每页显示数据条数时
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				var that = this
				that.pageSize = val;
				that.page_no = 1;
				that.getList();
				localStorage.setItem('page_no',that.page_no);
			},
			// 点击切换页的时候
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				var that = this
				// 当前页
				that.page_no = val;
				that.getList();
				localStorage.setItem('page_no',that.page_no);
			},
			// 点击去支付
			handleClick(item) {
				var that = this
				that.PaydialogVisible = true;
				that.pay_info = item; //订单信息
			},
			// 标签切换时
			changeIndex(index,id) {
				var that = this
				that.selecttype = index;
				that.page_no = 1;
				that.tab_id = id;
				that.getList();
				localStorage.setItem('tab_index',that.selecttype); //保存
				localStorage.setItem('page_no',that.page_no);
			},
			// 查看订单详情
			DetailsClick(id) {
				var that = this
				// 获取订单详情
				that.getdetailsList(id);
				that.centerDialogVisible = true;
			},
			// 获取订单详情
			getdetailsList(id){
				var that = this
				var obj = {
					id:id
				}
				getdingdandetailList(obj).then(res => {
					if(res.code == 1){
						that.details_info = res.data;
					}else{
						that.$message.error(res.msg);
					}
				})
			},
		},
		beforeDestroy() {
			localStorage.removeItem('tab_index'); //清空tab选择
			localStorage.removeItem('page_no'); //清空页码
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/order-center.scss';
	@import '../../assets/css/public.scss';
</style>
