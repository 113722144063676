<template>
	<!-- 修改密码 -->
	<div class="change-password">
		<div class="page-title">
			<div class="title-line"></div>
			<span>修改密码</span>
		</div>
		<div class="pass-main-box">
			<div class="pass-list">
				<div class="pass-item-name">原密码：</div>
				<input type="password" v-model="old_password" placeholder="请输入原密码" class="pass-item-input">
			</div>
			<div class="pass-list">
				<div class="pass-item-name">新密码：</div>
				<input type="password" v-model="new_password" placeholder="请输入新密码" class="pass-item-input">
			</div>
			<div class="pass-list">
				<div class="pass-item-name">确认新密码：</div>
				<input type="password" v-model="re_password" placeholder="请再次输入新密码" class="pass-item-input">
			</div>
		</div>
		<div class="pass-btn" @click="RevisePass">保存</div>
	</div>
</template>

<script>
	import {
		editmima
	}from '@/api/api.js'
	export default {
		data() {
			return {
				old_password:'',
				new_password:'',
				re_password:''
			}
		},
		created() {
			var that = this
		},
		methods: {
			// 修改密码
			RevisePass(){
				var that = this
				var obj = {
					old_password:that.old_password,
					password:that.new_password,
					re_password:that.re_password
				}
				editmima(obj).then(res => {
					if(res.code == 1){
						that.$message.success(res.msg);
						// 清空内容
						that.old_password = '';
						that.new_password = '';
						that.re_password = '';
					}else{
						that.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/change-password.scss';
	@import '../../assets/css/public.scss';
</style>
