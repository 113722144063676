<template>
	<div class="personal-center">
		<div class="per-main-box">
			<Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
			<div class="per-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>个人中心</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="per-all-list">
					<div class="l-list-box">
						<div class="l-people-card">
							<div class="img-box">
								<img :src="userinfo.logo" alt="">
							</div>
							<span>{{userinfo.name}}</span>
							<div class="position">业务员：{{userinfo.salesman}}</div>
							<div class="exit-btn" @click="exitLogin">退出登录</div>
						</div>
						<div class="l-list-menu">
							<div class="menu-item" v-for="(item,index) in menuarr" :key="index"
								:class="selectIndex == index ? 'menu-active' : ''" @click="changeIndex(index,item)">
								{{item}}
							</div>
						</div>
					</div>
					<div class="l-list-box l-list-box1">
						<div class="l-people-card">
							<div class="img-box">
								<img :src="userinfo.logo" alt="">
							</div>
							<div class="middle-box">
								<span>{{userinfo.name}}</span>
								<div class="position">业务员：{{userinfo.salesman}}</div>
							</div>
							<div class="exit-btn" @click="exitLogin">退出登录</div>
						</div>
						<div class="l-list-menu">
							<div class="menu-item" v-for="(item,index) in menuarr" :key="index"
								:class="selectIndex == index ? 'menu-active' : ''" @click="changeIndex(index,item)">
								{{item}}
								<div class="bottom-line" v-if="selectIndex == index"></div>
								<div class="bottom-zhanwei" v-else></div>
							</div>
						</div>
					</div>
					<div class="r-pluggable-unit">
						<PersonalData v-if="selectname == '个人资料'"></PersonalData>
						<OrderCenter v-if="selectname == '订单中心'"></OrderCenter>
						<MyCollection v-if="selectname == '我的收藏'"></MyCollection>
						<ChangePassword v-if="selectname == '修改密码'"></ChangePassword>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Sidder from '@/components/sidder/index.vue'
	import PersonalData from '@/components/people-center/personal-data.vue'
	import OrderCenter from '@/components/people-center/order-center.vue'
	import MyCollection from '@/components/people-center/my-collection.vue'
	import ChangePassword from '@/components/people-center/change-password.vue'
	import {
		getyhxinxi
	} from "@/api/api.js"
	export default {
		name:'Personal-Center',
		components: {
			Sidder,
			PersonalData,
			OrderCenter,
			MyCollection,
			ChangePassword
		},
		data() {
			return {
				tab_selectIndex: null,
				menuarr: ['个人资料', '订单中心', '我的收藏', '修改密码'],
				selectIndex: 0,
				selectname: '',
				userinfo: {},
				request_num:0,
			}
		},
		watch: {
			$route(to, from) {
				this.$router.go(0);
			}
		},
		mounted() {
			var that = this
		},
		activated() {
			var that = this
			if (localStorage.getItem('selectIndex')) {
				that.selectIndex = localStorage.getItem('selectIndex');
			} else {
				that.selectIndex = that.$route.query.personsiderIndex;
				
			}
			console.log(that.selectIndex )
			if(that.selectIndex == 2){
				localStorage.setItem('headerIndex', 3);
			}else{
				localStorage.setItem('headerIndex', 4);
			}
			that.selectname = that.menuarr[that.selectIndex];
			localStorage.getItem('headerIndex', that.selectIndex);
			that.getUserInfoList();
		},
		updated() {
			var that = this
		},
		methods: {
			
			// 获取个人信息
			getUserInfoList() {
				var that = this
				that.request_num ++
				var obj = {}
				getyhxinxi(obj).then(res => {
					if (res.code == 1) {
						var data = res.data;
						that.userinfo = data;
					}
				})
			},
			
			// 退出登录
			exitLogin() {
				var that = this
				that.$confirm('确定退出登录吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.removeItem('user_info'); // 删除登录信息
					localStorage.removeItem('homeframe'); // 删除控制首次弹框参数
					localStorage.removeItem('headerIndex'); // 删除顶部选中项
					localStorage.removeItem('person_index'); // 删除控制个人中心或收藏展示
					localStorage.removeItem('topselect'); // 删除控制个人中心或收藏展示
					localStorage.removeItem('selectIndex'); // 删除个人中心侧边栏选择
					localStorage.removeItem('options'); // 删除个人中心侧边栏选择
					location.href = '/';
				}).catch(() => {

				});
			},
			changeIndex(index, name) {
				var that = this
				that.selectIndex = index;
				that.selectname = name;
				localStorage.setItem('selectIndex', that.selectIndex);
				localStorage.setItem('person_index', that.selectIndex);
			}
		},
		
	}
</script>

<style lang="scss">
	@import '../../assets/css/personal-center.scss';
	@import '../../assets/css/public.scss';
</style>
