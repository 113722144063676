<template>
	<!-- 个人资料页 -->
	<div class="personal-data">
		<div class="page-title">
			<div class="title-line"></div>
			<span>个人资料</span>
		</div>
		<div class="data-all-list">
			<div class="data-list-item data-list-item1">
				<div class="data-item-name">头像：</div>
				<div class="data-item-content">
					<img :src="logo" alt="">
					<div class="upload-logo">
						<div class="upload-btn">修改</div>
						<input type="file" id="file1" name="file" ref="fileRef1" @change="change('头像',$event)">
					</div>
				</div>
			</div>
			<div class="data-list-item">
				<div class="data-item-name">身份：</div>
				<div class="data-item-content">
					<div class="item-text" @click="noticeBox">{{options}}</div>
				</div>
			</div>
			<div class="data-list-item" v-if="options == '个人'">
				<div class="data-item-name">昵称：</div>
				<div class="data-item-content">
					<input type="text" v-model="user_name" class="data-item-input" placeholder="">
				</div>
			</div>
			<div class="data-list-item" v-else>
				<div class="data-item-name">公司全称：</div>
				<div class="data-item-content">
					<input type="text" v-model="compny_name" class="data-item-input" placeholder="">
				</div>
			</div>
			<div class="data-list-item">
				<div class="data-item-name">手机号：</div>
				<div class="data-item-content">
					<input type="text" v-model="phone" class="data-item-input" placeholder="">
					<div class="phone-notice">修改手机号即登录账号也会对应变更,请谨慎修改！</div>
				</div>
			</div>
			<div class="data-list-item">
				<div class="data-item-name">地区：</div>
				<div class="data-item-content">
					<el-select v-model="province" placeholder="选择省" @change="shengchange">
						<el-option v-for="item in ProvinceList" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="city" placeholder="选择市" @change="shichange">
						<el-option v-for="item in CityList" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="area" placeholder="选择区">
						<el-option v-for="item in AreaList" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
					
				</div>
			</div>
			<div class="data-list-item data-list-item1" v-if="options == '公司'">
				<div class="data-item-name">营业执照/ 门头照：</div>
				<div class="data-item-content data-item-content1">
					<img :src="head_photo" alt="">
					<div class="upload-logo">
						<div class="upload-btn">重新上传</div>
						<input type="file" name="file" ref="fileRef2" @change="change('营业执照',$event)">
					</div>
				</div>
			</div>
			<div class="data-list-item">
				<div class="data-item-name">业务员：</div>
				<div class="data-item-content">
					<div class="item-text" @click="noticeBox">{{salesman}}</div>
				</div>
			</div>
		</div>
		<div class="data-preserve" @click="reviseInfo">保存</div>
	</div>
</template>

<script>
	import {
		getshenglist,
		getshilist,
		getqulist,
		getyhxinxi,
		edityhxinxi,
		getqntokenList
	} from "@/api/api.js"

	export default {
		data() {
			return {
				province: '', // 省
				city: '', // 市
				area: '', // 区/县
				ProvinceList: [], // 省列表
				CityList: [], // 市列表
				AreaList: [], // 区列表
				user_name:'', // 个人登录，昵称
				compny_name:'', // 公司登录  公司全称
				phone:'',
				logo:'', //头像
				options:'', // 身份
				head_photo:'', //营业执照
				salesman:'', //业务员
				HeaderImgKey:'', //修改时需要向后台传递的头像参数
				mtzImgKey:'', //修改时需要向后台传递的营业执照参数
				action: 'https://upload-z1.qiniup.com', // 上传七牛云网址
				userinfo:''
			}
		},
		created() {
			var that = this
			that.getprovincesList(); // 获取省份列表
			that.getUserInfoList();
			
		},
		methods: {
			noticeBox(){
				var that = this
				that.$message.error('不可修改！');
			},
			// 修改个人信息
			reviseInfo(){
				var that = this
				// 判断个人登录还是公司登录
				if(that.options == '个人'){
					var obj = {
						logo:that.HeaderImgKey,
						name:that.user_name,
						phone:that.phone,
						head_photo:that.mtzImgKey,
						province:that.province,
						city:that.city,
						area:that.area
					}
				}
				if(that.options == '公司'){
					var obj = {
						logo:that.HeaderImgKey,
						name:that.compny_name,
						phone:that.phone,
						head_photo:that.mtzImgKey,
						province:that.province,
						city:that.city,
						area:that.area
					}
				}
				edityhxinxi(obj).then(res => {
					if(res.code == 1){
						that.$message.success(res.msg);
						that.$router.go(0);
					}else{
						that.$message.error(res.msg);
					}
				})
			},
			// 获取个人信息
			getUserInfoList(){
				var that = this
				var obj = {}
				getyhxinxi(obj).then(res => {
					if(res.code == 1){
						var data = res.data;
						if(data.level == 1){
							that.options = '公司';
						}
						if(data.level == 2){
							that.options = '个人';
						}
						that.logo = data.logo; //头像
						// 获取头像key值
						var lastIndex = that.logo.lastIndexOf('/'); //获取/在字符串中最后出现的位置
						if(lastIndex != -1){
							that.HeaderImgKey = that.logo.slice(lastIndex + 1); //取key值
						}
						that.head_photo = data.head_photo; //营业执照
						// 获取营业执照key值
						var lastIndex1 = that.head_photo.lastIndexOf('/'); //获取/在字符串中最后出现的位置
						if(lastIndex1 != -1){
							that.mtzImgKey = that.head_photo.slice(lastIndex1 + 1); //取key值
						}
						that.user_name = data.name;//个人昵称
						that.compny_name = data.name; //公司全称
						that.phone = data.phone;
						that.salesman = data.salesman; //业务员
						that.province = data.province; //省
						// 获取市
						that.getcityList(that.province);
						that.city = data.city; //市
						// 获取区
						that.getareaList(that.city); 
						that.area =data.area; //区
					}
				})
			},
			// 获取省列表
			getprovincesList() {
				var that = this
				var obj = {}
				getshenglist(obj).then(res => {
					if(res.code == 1){
						that.ProvinceList = res.data;
					}
				})
			},
			// 省份变化时
			shengchange(e) {
				var that = this
				// 省发生变化时,对应的市和区都发生变化,故清空市  区/县的选择
				that.city = '';
				that.area = '';
				that.province = e;
				if (e) {
					// 根据所选择的省份获取对应的市
					that.getcityList(e);
				}
			},
			// 获取市列表
			getcityList(e) {
				var that = this
				var obj = {
					id: e
				}
				getshilist(obj).then(res => {
					if(res.code == 1){
						that.CityList = res.data;
					}
				})
			},
			shichange(e){
				var that = this
				// 市发生变化,则相应的区也变化,故清空区的选择
				that.area = '';
				that.city = e;
				if (e) {
					// 根据所选择的市获取对应的区/县
					that.getareaList(e);
				}
			},
			// 获取区/县列表
			getareaList(e){
				var that = this
				var obj = {
					id: e
				}
				getqulist(obj).then(res => {
					if(res.code == 1){
						that.AreaList = res.data;
					}
				})
			},
			change(name, e) {
				//打印上传的文件目录
				// console.log('上传文件列表',e.target.files)
				var that = this
				if(name == '头像'){
					var fileInput = this.$refs.fileRef1;
					var file = fileInput.files[0];
					var reader = new FileReader(); 
					reader.onload = function(e) { 
						that.logo = e.target.result; // 获取图片地址并赋值 
					}; 
					reader.readAsDataURL(file);
					// 获取七牛云token
					var obj = {}
					getqntokenList(obj).then(res => {
						var token = res.data;
						that.uploadImg('头像', token, file);
					})
				}
				if(name == '营业执照'){
					var fileInput = this.$refs.fileRef2;
					var file = fileInput.files[0];
					var reader = new FileReader(); 
					reader.onload = function(e) { 
						that.head_photo = e.target.result; // 获取图片地址并赋值 
					}; 
					reader.readAsDataURL(file);
					// 获取七牛云token
					var obj = {}
					getqntokenList(obj).then(res => {
						var token = res.data;
						that.uploadImg('营业执照', token, file);
					})
				}
				
			},
			// 上传图片到七牛云
			uploadImg(name, token, file) {
				var that = this
				//配置请求头
				const config = {
					headers: {
						"Content-Type": "multipart/form-data"
					},
				};
				var key = new Date().getTime() + Math.floor(Math.random() * 24)
				const formdata = new FormData();
				formdata.append("file", file);
				formdata.append("token", token);
				formdata.append("key", key);
				that.$http.post(that.action, formdata, config).then((res) => {
					if (name == '营业执照') {
						that.mtzImgKey = res.data.key;
					}
					if (name == '头像') {
						that.HeaderImgKey = res.data.key;
					}
				}).catch(() =>{
					
				})
			},
		},
		
	}
</script>

<style lang="scss">
	@import '../../assets/css/personal-data.scss';
	@import '../../assets/css/public.scss';
</style>
