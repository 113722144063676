<template>
	<!-- 我的收藏 -->
	<div class="my-collection">
		<div class="page-title">
			<div class="title-line"></div>
			<span>我的收藏</span>
		</div>
		<div class="order-type">
			<div class="typy-item" v-for="(item,index) in orderTab" :key="index"
				:style="{'font-weight':(selecttype == index? '600':'400')}" @click="changeIndex(index,item)">
				{{item}}
				<div class="type-line" v-if="selecttype == index"></div>
				<div class="type-line type-zhanwei" v-else></div>
			</div>
		</div>
		<!-- 有数据时 -->
		<div class="coll-main-box" v-if="HaveData">
			<div class="all-photographer">
				<div class="all-plg-item" v-for="(item,index) in dataList" :key="index"
					@click="changepage(item.id,item.name)">
					<div class="plg-item">
						<!-- <div class="top-box" :class="item.level == 1 ? 'csr-color-backbg':item.level == 2 ? 'zj-color-backbg':item.level == 3 ? 'sx-color-backbg':
						item.level == 4 ? 'gj-color-backbg':item.level == 5 ? 'zji-color-backbg':item.level == 6 ? 'zy-color-backbg':item.level == 7 ? 'sxdy-color-backbg':
						item.level == 8 ? 'sxzj-color-backbg': item.level == 9 ? 'sxsx-color-backbg': item.level == 10 ? 'sxgj-color-backbg':
						item.level == 11 ? 'sxzy-color-backbg': item.level == 12 ? 'sxjc-color-backbg': item.level == 13 ? 'sxfj-color-backbg':''"></div>
						
						<div class="footer-box" :class="item.level == 1 ? 'csr-footer-backbg':item.level == 2 ? 'zj-footer-backbg':item.level == 3 ? 'sx-footer-backbg':
						item.level == 4 ? 'gj-footer-backbg':item.level == 5 ? 'zji-footer-backbg':item.level == 6 ? 'zy-footer-backbg':item.level == 7 ? 'sxdy-footer-backbg':
						item.level == 8 ? 'sxzj-footer-backbg': item.level == 9 ? 'sxsx-footer-backbg': item.level == 10 ? 'sxgj-footer-backbg': 
						item.level == 11 ? 'sxzy-footer-backbg': item.level == 12 ? 'sxjc-footer-backbg':item.level == 13 ? 'sxfj-footer-backbg':''"></div> -->
						
						<div class="top-box zy-color-backbg"></div>
						
						<div class="footer-box zy-footer-backbg"></div>
						<img :src="item.image" alt="">
					</div>
					<div class="coll-info">
						<span class="info-name">{{item.name}}</span>
						<!-- <span class="info-price"
							:style="{'color':(item.level == 1 ? '#950BC9': item.level == 2 ? '#4DA0E7':item.level == 3 ? '#F7AE56':
							item.level == 4 ? '#8B80D5':item.level == 5 ? '#A7624A':item.level == 6 ? '#4A4D5E': item.level == 7 ? '#9203C7': 
							item.level == 8 ? '#499EE7': item.level == 9 ? '#EAAE65': item.level == 10 ? '#897DD4': item.level == 11 ? '#A66047':
							item.level == 12 ? '#4A4D5E': item.level == 13 ? '#6a8199':'')}">￥{{item.price}}</span> -->
							
						<span class="info-price" style="color:#4A4D5E">￥{{item.price}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="page-num" v-if="HaveData">
			<el-pagination @current-change='handleCurrentChange' background layout="prev, pager, next" :total="total"
				:page-size='pageSize' next-text="下一页">
			</el-pagination>
		</div>
		<!-- 无数据时 -->
		<div v-else class="nodata">
			<img src="../../assets/images/sidder/zanwuneirong.png" alt="">
			暂无数据~
		</div>
	</div>
</template>

<script>
	import {
		getshoucangList
	} from "@/api/joggle.js"
	export default {
		data() {
			return {
				orderTab: ['摄影师', '摄像师'],
				selecttype: 0,
				dataList: [],
				type: 1, // 1.摄影师 2.摄像师
				page_no: 1, //当前页
				total: 0, // 总数
				pageSize: 10, // 每页数量
				HaveData: true,
			}
		},

		created() {
			var that = this
			var type = that.$route.query.type;
			if (type) { // 摄影师或摄像师选中项（从详情返回时）
				if (type == '摄影师') {
					that.selecttype = 0;
					that.type = 1;
				}
				if (type == '摄像师') {
					that.selecttype = 1;
					that.type = 2;
				}
			}
			that.getList();
		},
		methods: {
			changepage(id, name) {
				var that = this
				// 摄影师
				if (that.type == 1) {
					that.$router.push({
						path: '/photography-details',
						query: {
							operate: '我的收藏',
							id: id,
							name: name
						}
					})
				}
				if (that.type == 2) {
					that.$router.push({
						path: '/video-details',
						query: {
							operate: '我的收藏',
							id: id,
							name: name
						}
					})
				}

			},
			// 获取我的收藏
			getList() {
				var that = this
				var obj = {
					type: that.type,
					page_no: that.page_no
				}
				getshoucangList(obj).then(res => {
					if (res.code == 1) {
						var data = res.data;
						that.total = data.count;
						that.pageSize = data.page_size;
						that.dataList = data.list; //"level": 1, //1.黄金档  2.白银档  3.青铜档
						if (that.dataList.length == 0) {
							that.HaveData = false;
						} else {
							that.HaveData = true;
						}
					}
				})
			},
			// 分页
			handleCurrentChange(val) {
				var that = this
				// 当前页
				that.page_no = val
				that.getList();
			},
			changeIndex(index, name) {
				var that = this
				that.selecttype = index;
				that.page_no = 1;
				if (name == '摄影师') { //1.摄影师 2.摄像师
					that.type = 1;
				}
				if (name == '摄像师') {
					that.type = 2;
				}
				that.getList();
			},
		}
	}
</script>

<style lang="scss">
	@import '../../assets/css/my-collection.scss';
	@import '../../assets/css/public.scss';
</style>